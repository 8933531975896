
const awsmobile = {
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_appsync_graphqlEndpoint: "https://r2a3u6gzbzdgjciebuhqvi46le.appsync-api.eu-central-1.amazonaws.com/graphql",
    aws_appsync_region: "eu-central-1",
    aws_cloud_logic_custom: [],
    aws_cognito_region: "eu-central-1",
    aws_project_region: "eu-central-1",
    aws_user_files_s3_bucket: "evap-ios8-photos",
    aws_user_files_s3_bucket_region: "eu-central-1",
    aws_user_pools_id: "eu-central-1_JpvJ7BXPt",
    aws_user_pools_web_client_id: "5kj3b1k8mrj54pesrv3gjulob7"
};

export default awsmobile;
