import React from "react";
import styles from "./styles.module.scss";

const SuccessResetView = () => (
  <div className={styles.success}>
    <div>
      <div className={styles.success__title}>Password Reset</div>
      <p className={styles.success__text}>
        Your password has been succesfully reset. <br />
        Open the app to log in.
      </p>
      <button className={styles.success__button} onclick="window.location.href = 'eventsapp://signin';">Open App</button>
    </div>
  </div>
);

export default SuccessResetView;
