import React, { Fragment } from "react";
import ResetPasswordForm from "../components/ResetPassword/ResetPasswordForm";
import queryString from "query-string";
import { Auth } from "aws-amplify";
import Toastr from "../components/Toastr";

const getQueryParams = decodedParams => {
  const queryParams = queryString.parse(decodedParams);
  console.log(queryParams);
  return queryParams;
};

class ResetPassword extends React.Component {
  state = (this.initialState = {
    password: "",
    confirmPassword: "",
    validationError: false,
    resetError: false
  });

  handleInputChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
    this.validatePassword(value);
  };

  fixEmailSpaces = email => {
    const fixedEmail = email.replace(" ", "+");
    return fixedEmail;
  };

  resetPassword = () => {
    const {
      location: { search },
      history: { push }
    } = this.props;
    const { confirmPassword } = this.state;
    const { code, email } = getQueryParams(search);
    const emailWithPlusSymbol = this.fixEmailSpaces(email);
    Auth.forgotPasswordSubmit(emailWithPlusSymbol, code, confirmPassword)
      .then(data => push("/success"))
      .catch(err => {
        this.setState({
          resetError: true,
          password: "",
          confirmPassword: ""
        });
        setTimeout(() => {
          this.setState({
            resetError: false
          });
        }, 5000);
      });
  };

  validatePassword = p => {
    if (p.length < 8) {
      this.setState({
        validationError: true
      });
      return true;
    }
    if (p.search(/[A-Z]/) < 0) {
      this.setState({
        validationError: true
      });
      return true;
    }
    if (p.search(/[a-z]/) < 0) {
      this.setState({
        validationError: true
      });
      return true;
    }
    if (p.search(/[a-z]/i) < 0) {
      this.setState({
        validationError: true
      });
      return true;
    }
    if (p.search(/[0-9]/) < 0) {
      this.setState({
        validationError: true
      });
      return true;
    } else {
      this.setState({
        validationError: false
      });
      return false;
    }
  };

  render() {
    const {
      password,
      confirmPassword,
      validationError,
      resetError
    } = this.state;
    const disabled =
      password !== confirmPassword ||
      (password.length === 0 && confirmPassword.length === 0);
    return (
      <Fragment>
        {resetError && <Toastr message="USER_RESET_FAILED" type="error" />}
        <ResetPasswordForm
          {...this.state}
          validationError={validationError}
          validatePassword={this.validatePassword}
          resetPassword={this.resetPassword}
          handleInputChange={this.handleInputChange}
          disabled={disabled}
        />
      </Fragment>
    );
  }
}

export default ResetPassword;
