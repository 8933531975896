import React from "react";
import styles from "./styles.module.scss";

class ResetPasswordForm extends React.Component {
  validateAndResetpassword = () => {
    let { validatePassword, confirmPassword, resetPassword } = this.props;
    let validateError = validatePassword(confirmPassword);
    if (!validateError) {
      resetPassword();
    }
    return null;
  };

  render() {
    const {
      handleInputChange,
      password,
      confirmPassword,
      disabled,
      resetPassword,
      validatePassword,
      validationError
    } = this.props;
    return (
      <div className={styles.form}>
        <div className={styles.form__title}>Choose a new password</div>
        <div className={styles.form__inputBox}>
          <label className={styles.form__label}>Password</label>
          <input
            name="password"
            type="password"
            value={password}
            onChange={handleInputChange}
            className={styles.form__input}
            placeholder=""
          />
          <p
            className={`${styles.form__text} ${
              validationError ? styles.form__textError : ""
            }`}
          >
            Minimum of 8 characters with at least 1 uppercase , 1 lowercase ,
            and 1 digit
          </p>
        </div>
        <div className={styles.form__inputBox}>
          <label className={styles.form__label}>Confirm Password</label>
          <input
            name="confirmPassword"
            type="password"
            value={confirmPassword}
            onChange={handleInputChange}
            className={styles.form__input}
            placeholder=""
          />
          <p
            className={`${styles.form__text} ${
              validationError ? styles.form__textError : ""
            }`}
          >
            {confirmPassword.length > 0 && password !== confirmPassword ? (
              <span>Passwords must be equal</span>
            ) : (
              "Minimum of 8 characters with at least 1 uppercase , 1 lowercase, and 1 digit"
            )}
          </p>
        </div>
        <button
          onClick={this.validateAndResetpassword}
          disabled={disabled}
          className={`${styles.form__resetBtn} ${
            disabled ? styles.form__resetBtndisabled : ""
          }`}
        >
          Reset my password
        </button>
      </div>
    );
  }
}

export default ResetPasswordForm;
