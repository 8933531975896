import React from "react";
import styles from "./styles.module.scss";

const Toastr = ({ message, type }) => (
  <div className={styles.toastr}>
    <p>{message}</p>
  </div>
);

export default Toastr;
