import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import ResetPassword from "./containers/ResetPassword";
import SuccessReset from "./containers/SuccessReset";

class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Redirect to="/forgotPassword" />}
          />
          <Route
            exact
            path="/forgotPassword"
            render={props => <ResetPassword {...props} />}
          />
          <Route exact path="/success" render={() => <SuccessReset />} />
        </Switch>
      </Router>
    );
  }
}


export default App;
